import React from "react";
import { Nav, Col, Row, Container } from "react-bootstrap";
import { Link } from "gatsby";

import styles from "./footer.module.scss";

class Footer extends React.Component {
  render() {
    return (
      <footer className={`${styles.footer} mt-5`}>
        <Nav className={`p-5`}>
          <Container>
            <Row>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`} hidden>
                <Nav.Item>
                  <div className={`${styles.navCategory} font-weight-bold`}>
                    Tools
                  </div>
                  <Link to="/" className="nav-link">
                    Tool1
                  </Link>
                  <Link to="/" className="nav-link">
                    Tool2
                  </Link>
                  <Link to="/" className="nav-link">
                    Tool3
                  </Link>
                </Nav.Item>
              </Col>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`} hidden>
                <Nav.Item>
                  <div className={`${styles.navCategory} font-weight-bold`}>
                    Resources
                  </div>
                  <Link to="/" className="nav-link">
                    Resource1
                  </Link>
                  <Link to="/" className="nav-link">
                    Resource2
                  </Link>
                  <Link to="/" className="nav-link">
                    Resource3
                  </Link>
                </Nav.Item>
              </Col>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`}>
                <Nav.Item>
                  <div className={`${styles.navCategory} font-weight-bold`}>
                    About
                  </div>
                  <Link to="/company" className="nav-link">
                    Company
                  </Link>
                  <Link to="/team" className="nav-link">
                    Team
                  </Link>
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </Nav.Item>
              </Col>
              <Col xs={6} md={3} className={`mt-3 mt-md-0`}>
                <Nav.Item>
                  <div className={`${styles.navCategory} font-weight-bold`}>
                    Legal
                  </div>
                  <Link to="/terms" className="nav-link">
                    Terms
                  </Link>
                  <Link to="/privacy" className="nav-link">
                    Privacy
                  </Link>
                  <Link to="/earnings-disclaimer" className="nav-link">
                    Earnings Disclaimer
                  </Link>
                  <Link to="/faq" className="nav-link">
                    FAQ
                  </Link>
                </Nav.Item>
              </Col>
            </Row>
          </Container>
        </Nav>
        <section className={styles.footerCopyright}>
          <p className={`pb-3 m-0`}>
            Copyright © 2024 BusinessPro.co. All rights reserved.
          </p>
          <p className={`text-muted pb-3 m-0`} style={{ fontSize: 12 }}>
            All third party product, company names, logos, trademarks, and
            images shown on BusinessPro.co are copyright their respective
            owners.
          </p>
        </section>
      </footer>
    );
  }
}

export default Footer;
