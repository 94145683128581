import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";
import injectSheet from "react-jss";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import SearchForm from "../components/search-form";

const styles = {
  category: {
    color: "black !important",
    fontSize: "14px !important",
    whiteSpace: "nowrap",
    maxWidth: 180,
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: 6,
    "&:hover": {
      backgroundColor: "#e5e8eb",
    },
    "& i": {
      fontSize: 16,
      verticalAlign: "text-bottom",
    },
  },
};

class Header extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            categories: allMarkdownRemark(
              filter: {
                fileAbsolutePath: { regex: "/(categories)/.*.md$/" }
                frontmatter: { template: { eq: "category" } }
              }
              limit: 10
            ) {
              edges {
                node {
                  frontmatter {
                    title
                    slug
                    icon
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Helmet>
              <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                crossOrigin="anonymous"
              />
              <link
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                type="text/css"
                charset="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
              />
            </Helmet>
            <header>
              <Navbar bg="secondary" variant="dark" expand="lg">
                <Link to="/" className="navbar-brand">
                  <i
                    className={`material-icons mr-1`}
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    business
                  </i>
                  BusinessPro
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className="justify-content-end"
                >
                  <Nav className="mr-md-5 mr-xs-0">
                    <NavDropdown title="Categories" id="nav-dropdown">
                      {data.categories.edges &&
                        data.categories.edges.map((n, index) => (
                          <Link
                            className={`${classes.category} nav-link`}
                            key={index}
                            to={`/categories/${n.node.frontmatter.slug}`}
                          >
                            <i className={`material-icons mr-1`}>
                              {n.node.frontmatter.icon}
                            </i>
                            {n.node.frontmatter.title}
                          </Link>
                        ))}
                    </NavDropdown>
                    <Link to="/articles" className="nav-link mr-3">
                      Articles
                    </Link>
                  </Nav>
                </Navbar.Collapse>
                <SearchForm />
              </Navbar>
            </header>
          </>
        )}
      />
    );
  }
}

const StyledHeader = injectSheet(styles)(Header);

export default StyledHeader;
