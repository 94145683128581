import React from "react";
import { navigate } from "gatsby";
import shortid from "shortid";
import { Form, FormControl, Button, Row, Col } from "react-bootstrap";

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputNode = React.createRef();
  }
  componentDidMount() {
    this.inputNode.current.focus();
  }
  handleFormSubmit = (e) => {
    e.preventDefault();
    const query = this.inputNode.hasOwnProperty("current")
      ? this.inputNode.current.value
      : "";
    const p = new URLSearchParams();
    p.set("q", query);
    p.set("t", shortid.generate());
    return navigate(`/search/?${p.toString()}`);
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 27) {
      this.inputNode.current.value = "";
    }
  };

  render() {
    return (
      <Form inline onSubmit={this.handleFormSubmit}>
        <Col xs="8" className="pl-0">
          <FormControl
            type="text"
            placeholder="Search"
            className="mr-sm-2"
            ref={this.inputNode}
            onKeyUp={this.handleKeyUp}
          />
        </Col>
        <Button xs="2" variant="outline-light" type="submit">
          Search
        </Button>
      </Form>
    );
  }
}

export default SearchForm;
